import { HelpPanel } from '@amzn/awsui-components-react';

export function DashboardMainInfo() {
	return (
		<HelpPanel header={<h2>Beta Tester Portal</h2>}>
			<p>
				Beta Tester Portal is a beta lifecycle management tool that offers self-service dashboard to beta testers.
			</p>
		</HelpPanel>
	);
}
