import React from 'react';
import LoadingOverlay from 'react-loading-overlay-ts';

const CustomLoadingOverlay = ({active, text, children = null}: {
    active: boolean,
    text: string,
    children?: React.JSX.Element | null
}) => {
    return <LoadingOverlay
        active={active}
        text={text}
    >
        {children ? children : <div style={{height: '100vh'}}/>}
    </LoadingOverlay>;
};

export default CustomLoadingOverlay;