import {createContext, useContext} from 'react';
import {Nullable} from 'src/type/common-type';
import {IGetProfileOperationResponse, ILinkToMidwayOperationResponse} from 'src/type/operation/user-operation';
import {IUserProfileModel} from 'src/model/auth/user';

export interface AuthContextContextType {
	isAuthenticated: boolean;
	profile: Nullable<IUserProfileModel>;
	loading: boolean;
	logout: () => Promise<void>;
	login: () => Promise<IGetProfileOperationResponse>;
	linkToMidway: (midwayToken: string, midwayAlias: string) => Promise<ILinkToMidwayOperationResponse>;
	getName: () => string;
}

export const AuthContext = createContext({} as AuthContextContextType);
const useAuthContext = () => useContext(AuthContext);


export default useAuthContext;