import { useEffect, useRef, useState } from 'react';
import { useContainerQuery } from '@amzn/awsui-component-toolkit';
import { load, remove, save } from 'src/helper/local-storage';
import { getDefaultLayout } from 'src/pages/dashboard/widgets/widgets';
import { StoredWidgetPlacement } from 'src/pages/interfaces';

export const layoutStorageKey = 'item-layout';

export function useItemsLayout() {
	const [width, ref] = useContainerQuery((entry) => entry.contentBoxWidth);
	const [layout, setLayout] = useState<ReadonlyArray<StoredWidgetPlacement> | null>(
		() => load(layoutStorageKey) ?? null,
	);
	const itemsChanged = useRef(layout !== null);

	useEffect(() => {
		if (itemsChanged.current || !width) {
			return;
		}
		setLayout(getDefaultLayout());
	}, [width]);

	function handleLayoutChange(layout: ReadonlyArray<StoredWidgetPlacement>) {
		itemsChanged.current = true;
		save(layoutStorageKey, layout);
		setLayout(layout);
	}

	function resetLayout() {
		itemsChanged.current = false;
		remove(layoutStorageKey);
		setLayout(getDefaultLayout());
	}

	return { ref, layout: layout ?? [], setLayout: handleLayoutChange, resetLayout };
}
