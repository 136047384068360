import axios from 'axios';
import {BACK_END_URL} from 'src/env/env';
import {AppPath} from 'src/app-path';

const axiosInstance = axios.create({
    baseURL: BACK_END_URL,
    headers: {
        'Content-Type': 'application/json; charset=UTF-8',
    },
    withCredentials: true
});

axiosInstance.interceptors.response.use(function (response) {
    return response.data;
}, function (error) {
    if (401 === error.response.status) {
        window.location.href = AppPath.login.index;
    } else {
        return Promise.reject(error);
    }
});

export default axiosInstance;