import React from 'react';
import {AppLayout} from '@amzn/awsui-components-react/polaris';
import {Outlet} from 'react-router';
import logo from 'src/images/amazon_beta.png';
import {HelpPanel, TopNavigation} from '@amzn/awsui-components-react';

const TopNavigationLayout = () => {
    return (
        <div>
            <TopNavigation
                data-testid="top-navigation-id"
                identity={{
                    href: '/',
                    title: 'Beta Tester Portal',
                    logo: { src: logo, alt: 'Beta Tester Portal' },
                }}
            />
            <AppLayout
                navigationHide={true}
                tools={<HelpPanel header={<h2>Overview</h2>}>Help content</HelpPanel>}
                content={
                    <React.Suspense fallback={<p>Loading...</p>}>
                        <Outlet />
                    </React.Suspense>
                }
            />
        </div>

    );
};
export default TopNavigationLayout;
