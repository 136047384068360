import Header from '@amzn/awsui-components-react/polaris/header';
import { WidgetConfig } from '../interfaces';
import { Box, ColumnLayout, Link } from '@amzn/awsui-components-react';
import { StatsOverViewInfo } from 'src/pages/dashboard/widgets/stats-overview/help-content';
import { InfoLink } from 'src/components/info-link/info-link';
import { useHelpPanel } from 'src/hooks/help-panel/help-panel';

export const DashboardStatsOverview: WidgetConfig = {
	definition: { defaultRowSpan: 2, defaultColumnSpan: 6 },
	data: {
		icon: 'list',
		title: 'Stats Overview',
		description: 'Overview of the stats of your active projects',
		header: DashboardStatsOverviewHeader,
		content: DashboardOverviewStatsWidget,
	},
};

function DashboardStatsOverviewHeader() {
	const loadHelpPanelContent = useHelpPanel();
	return (
		<Header
			variant='h2'
			description='Aggregated stats'
			info={
				<InfoLink
					data-testid='stats-overview-info-link'
					onFollow={() => loadHelpPanelContent(<StatsOverViewInfo />)}
				/>
			}
		>
			Stats Overview
		</Header>
	);
}

function DashboardOverviewStatsWidget() {
	return (
		<ColumnLayout columns={4} variant='text-grid' minColumnWidth={170}>
			<div>
				<Box variant='awsui-key-label'>Active Projects</Box>
				<Link variant='awsui-value-large' href='#' ariaLabel='Active Projects'>
					0
				</Link>
			</div>
			<div>
				<Box variant='awsui-key-label'>Surveys</Box>
				<Link variant='awsui-value-large' href='#' ariaLabel='Surveys'>
					0
				</Link>
			</div>
			<div>
				<Box variant='awsui-key-label'>Assignments</Box>
				<Link variant='awsui-value-large' href='#' ariaLabel='Assignments'>
					0
				</Link>
			</div>
			<div>
				<Box variant='awsui-key-label'>Notifications</Box>
				<Link variant='awsui-value-large' href='#' ariaLabel='Notifications'>
					0
				</Link>
			</div>
		</ColumnLayout>
	);
}
