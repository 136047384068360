import TopNavigationLayout from 'src/layout/top-navigation/top-navigation-layout';
import {AuthProvider} from 'src/auth/auth-provider';
import SecuredRoute from 'src/route/secured-route';

const TopNavigationWrapper = () => {
    return (
        <AuthProvider>
            <SecuredRoute>
                <TopNavigationLayout/>
            </SecuredRoute>
        </AuthProvider>
    );
};

export default TopNavigationWrapper;