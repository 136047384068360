import React, {useContext, useRef, useState} from 'react';
import {AppLayout, AppLayoutProps, SplitPanel, TopNavigation} from '@amzn/awsui-components-react';
import {DashboardSideNavigation} from 'src/pages/dashboard/side-navigation/side-navigation';
import DashboardBreadcrumbs from 'src/layout/dashboard/parts/dashboard-breadcrumbs';
import {Outlet} from 'react-router';
import {SideNavigationProps} from '@amzn/awsui-components-react/polaris/side-navigation';
import {Navigate, useNavigate} from 'react-router-dom';
import {getPaletteWidgets} from 'src/pages/dashboard/widgets/widgets';
import Palette from 'src/components/pallete/palette';
import {useLocalStorage} from 'src/hooks/local-storage/use-local-storage';
import {DashboardContext, DashboardContextContextType} from 'src/layout/dashboard/dashboard-context';
import {DashboardMainInfo} from 'src/pages/dashboard/home/parts/dashboard-main-info';
import {HelpPanelProvider} from 'src/hooks/help-panel/help-panel';
import logo from '../../images/amazon_beta.png';
import {AppPath} from 'src/app-path';
import useAuthContext from 'src/auth/auth-context';

const splitPanelMaxSize = 360;

const DashboardLayout = () => {
    const auth = useAuthContext();

    const [toolsOpen, setToolsOpen] = useState(false);
    const [toolsContent, setToolsContent] = useState<React.ReactNode>(() => <DashboardMainInfo/>);

    const navigate = useNavigate();
    const [splitPanelSize, setSplitPanelSize] = useLocalStorage('split-panel-size', splitPanelMaxSize);
    const {splitPanelOpen, setSplitPanelOpen, layout}: DashboardContextContextType | any =
        useContext(DashboardContext);

    const appLayoutRef = useRef<AppLayoutProps.Ref>(null);

    const loadHelpPanelContent = (content: React.ReactNode) => {
        setToolsOpen(true);
        setToolsContent(content);
        appLayoutRef.current?.focusToolsClose();
    };

    const onFollowHandler: SideNavigationProps['onFollow'] = (event) => {
        event.preventDefault();
        navigate(event.detail.href);
    };

    // TODO: This will call Auth Portal logout function after auth is enabled
    const handleUserMenuItemClick = async (clickDetails: any) => {
        console.log('ItemClick - ID:' + clickDetails.detail.id);
    };

    const profileActions = [{id: 'profile', text: 'Profile', href: AppPath.user.profile.index}, {
        id: 'link-to-midway',
        text: 'Link to Midway',
        href: AppPath.user.profile.midwayLink
    }, {id: 'logout', text: 'Logout'}];

    const authContext = useAuthContext();

    if (!authContext.profile?.midwayAlias) {
        return <Navigate to={AppPath.user.profile.midwayLink} replace={true} />;
    }

    return (
        <HelpPanelProvider value={loadHelpPanelContent}>
            <>
                <TopNavigation
                    data-testid="dashboard-top-nav"
                    identity={{
                        href: '/',
                        title: 'Beta Tester Portal',
                        logo: {src: logo, alt: 'Beta Tester Portal'},
                    }}
                    utilities={[
                        {
                            type: 'button',
                            iconName: 'notification',
                            ariaLabel: 'Notifications',
                            disableUtilityCollapse: true,
                        },
                        {type: 'button', iconName: 'settings', title: 'Settings', ariaLabel: 'Settings'},
                        {
                            title: `${auth.getName()}`,
                            type: 'menu-dropdown',
                            text: `${auth.getName()}`,
                            ariaLabel: `Welcome ${auth.getName()}`,
                            description: `Welcome ${auth.getName()}`,
                            iconName: 'user-profile',
                            onItemClick: handleUserMenuItemClick,
                            items: profileActions,
                        },
                    ]}
                />
                <AppLayout
                    ref={appLayoutRef}
                    contentType='dashboard'
                    breadcrumbs={<DashboardBreadcrumbs onFollow={onFollowHandler}/>}
                    navigation={<DashboardSideNavigation/>}
                    toolsOpen={toolsOpen}
                    tools={toolsContent}
                    onToolsChange={({detail}: any) => setToolsOpen(detail.open)}
                    content={
                        <React.Suspense fallback={<p>Loading...</p>}>
                            <Outlet/>
                        </React.Suspense>
                    }
                    splitPanel={
                        <SplitPanel header='Add widgets' closeBehavior='hide' hidePreferencesButton={true}>
                            <Palette items={getPaletteWidgets(layout)}/>
                        </SplitPanel>
                    }
                    splitPanelPreferences={{position: 'side'}}
                    onSplitPanelPreferencesChange={() => {
                    }} // TODO
                    splitPanelOpen={splitPanelOpen}
                    onSplitPanelToggle={({detail}) => setSplitPanelOpen(detail.open)}
                    splitPanelSize={splitPanelSize}
                    stickyNotifications={true}
                    onSplitPanelResize={(event) => setSplitPanelSize(Math.min(event.detail.size, splitPanelMaxSize))}
                />
            </>
        </HelpPanelProvider>
    );
};

export default DashboardLayout;
