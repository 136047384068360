import React, {useCallback, useMemo, useState} from 'react';
import {AuthContext} from 'src/auth/auth-context';
import {AuthPortalMiddleware} from 'src/auth/auth-portal-middleware';
import {AuthService} from 'src/auth/auth-service';
import {useLocalStorage} from 'src/hooks/local-storage/use-local-storage';
import {UserService} from 'src/service/user-service';
import {IUserProfileModel} from 'src/model/auth/user';

export const USER_STORAGE_KEY = 'user';

export const AuthProvider = ({children}: { children: React.JSX.Element }) => {
    const [profile, setProfile] = useLocalStorage(USER_STORAGE_KEY, {} as IUserProfileModel);
    const [loading, setLoading] = useState(false);
    const logout = useCallback(async () => {
        setProfile({} as IUserProfileModel);
        window.location.href = await AuthPortalMiddleware.createLogoutUrl();
    }, [profile]);

    const isAuthenticated = useMemo(() => {
        return !!profile?.userId;
    }, [profile]);

    const getName = useCallback(() => {
        return profile.midwayAlias || '@guest';
    }, [profile]);

    const login = useCallback(async () => {
        setLoading(true);
        try {
            const currentProfile = await AuthService.getUserProfile();
            setProfile(currentProfile);
            return currentProfile;
        } catch (e) {
            console.error(e);
            return Promise.reject('failed to get user profile.');
        } finally {
            setLoading(false);
        }
    }, []);

    const linkToMidway = useCallback(async (midwayToken: string, midwayAlias: string) => {
        try {
            const linkedMidway = await UserService.linkToMidway(midwayToken, midwayAlias);
            setProfile({...profile, midwayAlias: linkedMidway.midwayAlias});
            return linkedMidway;
        } catch (e) {
            console.error(e);
            return Promise.reject('failed to link midway to user profile.');
        }
    }, [profile]);

    const memoizedValue = useMemo(
        () => ({
            loading,
            isAuthenticated,
            logout,
            getName,
            login,
            linkToMidway,
            profile
        }),
        [logout, loading, isAuthenticated, getName, login, profile],
    );

    return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
};
