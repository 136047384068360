import {MbmProvider} from '@amzn/react-arb-tools';
import {LocalizationContextBuilder} from '@amzn/arb-tools';
import {RouterProvider} from 'react-router-dom';
import {appRouter} from 'src/route/router';
import {DEFAULT_LOCALE} from 'src/constants';
import {resolveBundleUrlWithFallbacks} from 'src/helpers';
import {arbManifest} from 'src/i18n/translations/arbManifest';
import {AlertProvider} from 'src/hooks/alert/alert-context';

const mbmOptions = {
    arbManifest,
    defaultLocalizationContext: new LocalizationContextBuilder()
        .withLocale(DEFAULT_LOCALE)
        .withDefaultLocale(DEFAULT_LOCALE)
        .build(),
    resolveBundleUrl: (resourcePackPath: string | undefined, locale: string, bundle: string) =>
        resolveBundleUrlWithFallbacks(resourcePackPath, locale, bundle),
};
const App = () => {
    return (
        <MbmProvider {...mbmOptions}>
            <AlertProvider>
                <RouterProvider router={appRouter}/>
            </AlertProvider>
        </MbmProvider>
    );
};

export default App;
