/* eslint @typescript-eslint/no-var-requires: "off" */
export const mixedContent = {
	light: require('./assets/mixed-content-light.svg').default,
	dark: require('./assets/mixed-content-dark.svg').default,
};

export const barChart = {
	light: require('./assets/bar-chart-light.svg').default,
	dark: require('./assets/bar-chart-dark.svg').default,
};

export const lineChart = {
	light: require('./assets/line-chart-light.svg').default,
	dark: require('./assets/line-chart-dark.svg').default,
};

export const list = {
	light: require('./assets/list-light.svg').default,
	dark: require('./assets/list-dark.svg').default,
};

export const pieChart = {
	light: require('./assets/pie-chart-light.svg').default,
	dark: require('./assets/pie-chart-dark.svg').default,
};

export const table = {
	light: require('./assets/table-light.svg').default,
	dark: require('./assets/table-dark.svg').default,
};
