import SecuredRoute from 'src/route/secured-route';
import DashboardLayout from 'src/layout/dashboard/dashboard-layout';
import DashboardProvider from 'src/layout/dashboard/dashboard-context';
import {AuthProvider} from 'src/auth/auth-provider';

const DashboardWrapper = () => {
    return (
        <DashboardProvider>
            <AuthProvider>
                <SecuredRoute>
                    <DashboardLayout/>
                </SecuredRoute>
            </AuthProvider>
        </DashboardProvider>
    );
};

export default DashboardWrapper;
