import { Button, SpaceBetween, TextFilter } from '@amzn/awsui-components-react';
import ItemsPalette, { ItemsPaletteProps } from '@amzn/awsui-board-components/items-palette';
import { useCollection } from '@amzn/awsui-collection-hooks';
import { WidgetDataType } from 'src/pages/dashboard/widgets/interfaces';
import { EmptyState } from 'src/pages/common/empty-state';
import { getTextFilterCounterText } from 'src/helper/text-filter';
import { PaletteItem } from 'src/components/pallete/palette-item';

function compareStrings(value: string, query: string) {
	return value.toLowerCase().includes(query.toLowerCase());
}

interface PaletteProps {
	items: ReadonlyArray<ItemsPaletteProps.Item<WidgetDataType>>;
}

export const paletteI18nStrings: ItemsPaletteProps.I18nStrings<WidgetDataType> = {
	navigationAriaLabel: 'Items palette navigation',
	navigationAriaDescription: 'Click on an item to move focus over',
	navigationItemAriaLabel: (item) => item.data.title,
	liveAnnouncementDndStarted: 'Dragging',
	liveAnnouncementDndDiscarded: 'Insertion discarded',
};

export default function Palette({ items: allItems }: PaletteProps) {
	const { items, filterProps, collectionProps, filteredItemsCount, actions } = useCollection(allItems, {
		filtering: {
			filteringFunction: (item, filteringText) =>
				compareStrings(item.data.title, filteringText) || compareStrings(item.data.description, filteringText),
			empty: (
				<EmptyState
					title='No more widgets'
					description='All widgets were added to the dashboard already.'
					verticalCenter={true}
				/>
			),
			noMatch: (
				<EmptyState
					title='No matches'
					description="We can't find a match."
					verticalCenter={true}
					action={<Button onClick={() => actions.setFiltering('')}>Clear filter</Button>}
				/>
			),
		},
		sorting: {
			defaultState: {
				sortingColumn: { sortingComparator: (a, b) => (a.data.title > b.data.title ? 1 : -1) },
			},
		},
	});

	return (
		<SpaceBetween size='l'>
			<TextFilter
				{...filterProps}
				filteringAriaLabel='Filter widgets'
				filteringPlaceholder='Find widgets'
				filteringClearAriaLabel='Clear'
				countText={getTextFilterCounterText(filteredItemsCount!)}
			/>
			{items.length > 0 ? (
				<ItemsPalette
					items={items}
					i18nStrings={paletteI18nStrings}
					renderItem={(item, { showPreview }) => (
						<PaletteItem
							title={item.data.title}
							description={item.data.description}
							iconName={item.data.icon}
							showPreview={showPreview}
						/>
					)}
				/>
			) : (
				collectionProps.empty
			)}
		</SpaceBetween>
	);
}
