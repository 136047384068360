import { SideNavigationProps } from '@amzn/awsui-components-react/polaris/side-navigation';
import { CommonNavigation } from './common-navigation';
import { useLocation, useNavigate } from 'react-router-dom';

const navItems: SideNavigationProps['items'] = [
	{ type: 'link', text: 'Dashboard', href: '/' },
	{
		type: 'link',
		text: 'Projects',
		href: '/projects',
	},
];

export function DashboardSideNavigation() {
	const location = useLocation(); //TODO: we need more robust way for setting activeHref in side nav bar

	const navigate = useNavigate();
	const onFollowHandler: SideNavigationProps['onFollow'] = (event) => {
		event.preventDefault();
		navigate(event.detail.href);
	};

	return (
		<>
			<CommonNavigation items={navItems} activeHref={location.pathname} onFollowHandler={onFollowHandler} />
		</>
	);
}
