import axiosInstance from 'src/service/axios-service';
import {ApiPath} from 'src/app-path';
import {ILinkToMidwayOperationResponse} from 'src/type/operation/user-operation';

export class UserService {
    public static async linkToMidway(midwayTokenId: string, midwayUsername: string): Promise<ILinkToMidwayOperationResponse> {
        return axiosInstance.post(ApiPath.user.midway.addLink, {
            midwayToken: midwayTokenId,
            midwayAlias: midwayUsername
        });
    }
}