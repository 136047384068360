import {UIMatch, useMatches} from 'react-router';
import {Breadcrumbs} from 'src/components/breadcrumbs/breadcrumbs';
import {SideNavigationProps} from '@amzn/awsui-components-react/polaris/side-navigation';

const DashboardBreadcrumbs = ({ onFollow }: { onFollow: SideNavigationProps['onFollow'] }) => {
	const initialMatches: UIMatch<any, any>[] = useMatches();

	const crumbs = initialMatches.filter((match) => Boolean(match.handle?.crumb)).map((match) => match.handle.crumb);

	const items = crumbs.map((crumb: any) => ({ text: crumb.text as string, href: crumb.href as string }));

	return <Breadcrumbs items={items} onFollow={onFollow} />;
};

export default DashboardBreadcrumbs;
