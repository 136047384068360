import React from 'react';
import {createRoot} from 'react-dom/client';


import {Spinner} from '@amzn/awsui-components-react';
import ErrorBoundary from 'src/components/error-boundry/error-boundry';
import '../styles/base.scss';
import AppInitWrapper from 'src/app-init-wrapper';
import App from 'src/app';

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
    <ErrorBoundary>
        <AppInitWrapper>
            <React.Suspense fallback={<Spinner/>}>
                <App/>
            </React.Suspense>
        </AppInitWrapper>
    </ErrorBoundary>,
);
