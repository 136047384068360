import Header from '@amzn/awsui-components-react/polaris/header';
import BoardItem from '@amzn/awsui-board-components/board-item';
import Box from '@amzn/awsui-components-react/polaris/box';
import * as icons from '../../../layout/dashboard/icons';
import styles from './styles.module.scss';
import { EmptyState } from 'src/pages/common/empty-state';
import { boardItemI18nStrings } from 'src/i18n-strings';

type IconName = keyof typeof icons;

interface PaletteItemProps {
	title: string;
	iconName: IconName;
	description: string;
	showPreview: boolean;
}

function PaletteIcon({ iconName }: { iconName: IconName }) {
	const icon = icons[iconName];
	const altText = `${iconName} icon`;

	return (
		<>
			<img className='awsui-util-hide-in-dark-mode' src={icon.light} alt={altText} />
			<img className='awsui-util-show-in-dark-mode' src={icon.dark} alt={altText} />
		</>
	);
}

export function PaletteItem({ title, iconName, description, showPreview }: PaletteItemProps) {
	return (
		<BoardItem header={<Header headingTagOverride='h3'>{title}</Header>} i18nStrings={boardItemI18nStrings}>
			{showPreview ? (
				<EmptyState
					title={title}
					description={description}
					icon={<PaletteIcon iconName={iconName} />}
					verticalCenter={true}
				/>
			) : (
				<div className={styles.layout}>
					<div>
						<PaletteIcon iconName={iconName} />
					</div>
					<Box variant='p'>{description}</Box>
				</div>
			)}
		</BoardItem>
	);
}
