import {Outlet} from 'react-router';
import logo from 'src/images/amazon_beta.png';
import {TopNavigation} from '@amzn/awsui-components-react';
import styled from 'styled-components';

const AuthDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: 100vh;
  width: 100vw;
`;

const AuthLayout = () => {
    return (
        <>
            <TopNavigation
                data-testid="top-navigation"
                identity={{
                    href: '/',
                    title: 'Beta Tester Portal',
                    logo: {src: logo, alt: 'Beta Tester Portal'},
                }}
            />
            <AuthDiv data-testid="auth-wrapper">
                <Outlet/>
            </AuthDiv>
        </>
    );
};

export default AuthLayout;