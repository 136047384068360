export const AppPath = {
    dashboard: {
        index: '/',
        bugHistory: {
            path: 'bugform/:bugFormId/bug-history',
            url: (bugFormId: string) => 
                `/bugForm/${bugFormId}/bug-history`,
        }
    },
    project: {
        index: '/projects',
    },
    login: {
        index: '/login',
    },
    user: {
        profile: {
            index: '/profile',
            midwayLink: '/link-midway-account',
        }
    },
};

export const apiVersion = '/v1';

export const ApiPath = {
    login: `${apiVersion}/user/login`,
    user: {
        profile: {
            getProfile: `${apiVersion}/user/profile`
        },
        midway: {
            addLink: `${apiVersion}/user/link-to-midway`,
        },
        projects: {
            getProjects: `${apiVersion}/user/projects`
        },
        bugform: {
            getBugForm: `${apiVersion}/user/bugform`
        },
        bug: {
            addBug: `${apiVersion}/user/add-bug`,
            bugHistory: `${apiVersion}/user/list-bugs`,
            getBug: `${apiVersion}/user/get-bug`,
            updateBug: `${apiVersion}/user/update-bug`
        }
    }
};

export const listProjectsUrl = () => {
    return AppPath.project.index;
};

export const viewBugHistoryUrl = (bugFormId: string) => {
	return AppPath.dashboard.bugHistory.url(bugFormId);
};