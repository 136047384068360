import {createBrowserRouter} from 'react-router-dom';
import {lazy} from 'react';
import {AppPath} from 'src/app-path';
import DashboardWrapper from 'src/layout/dashboard/dashboard-wrapper';
import AuthLayout from 'src/layout/auth/auth-layout';
import TopNavigationWrapper from 'src/layout/top-navigation/top-navigation-wrapper';

const LazyLoginPage = lazy(() => import('src/pages/auth/login-page'));
const LazyProfilePage = lazy(() => import('src/pages/profile/profile-page'));
const LazyCreateAccountPage = lazy(() => import('src/pages/create-account/create-account-page'));
const LazyMidwayLinkPage = lazy(() => import('src/pages/midway-link/midway-link-page'));
const LazyListProjectPage = lazy(() => import('src/pages/project/project-list-page'));
const LazyProjectPage = lazy(() => import('src/pages/dashboard/projects/project-page'));
const LazyBugHistoryListPage = lazy(() => import('src/pages/bug-history/parts/bug-history-list-page'));

export const routesConfig = [
	{
		element: <DashboardWrapper />,
		children: [
			{
				path: AppPath.dashboard.index,
				element: <LazyProjectPage />,
				handle: {
					crumb: {
						text: 'Dashboard',
						href: AppPath.dashboard.index,
					},
				},
			}
		],
	},
	{
		element: <AuthLayout />,
		children: [
			{
				path: AppPath.login.index,
				element: <LazyLoginPage />,
				handle: {
					crumb: {
						text: 'Login',
						href: AppPath.login.index,
					},
				},
			},
		]
	},
	{
		element: <DashboardWrapper />,
		children: [
			{
				path: AppPath.user.profile.index,
				element: <LazyProfilePage />,
				handle: {
					crumb: {
						text: 'Profile',
						href: AppPath.user.profile.index,
					},
				},
			},
		]
	},
	{
		element: <TopNavigationWrapper />,
		children: [
			{
				path: AppPath.user.profile.midwayLink,
				element: <LazyCreateAccountPage />,
				handle: {
					crumb: {
						text: 'Link Midway Account',
						href: AppPath.user.profile.midwayLink,
					},
				},
			},
		]
	},
	{
		element: <DashboardWrapper />,
		children: [
			{
				path: AppPath.user.profile.midwayLink,
				element: <LazyMidwayLinkPage />,
				handle: {
					crumb: {
						text: 'Midway Linking',
						href: AppPath.user.profile.midwayLink,
					},
				},
			},
		]
	},
	{
		element: <DashboardWrapper />,
		children: [
			{
				path: AppPath.project.index,
				element: <LazyListProjectPage />,
				handle: {
					crumb: {
						text: 'Project',
						href: AppPath.project.index,
					},
				},
			},
		]
	},
	{
		element: <DashboardWrapper />,
		children: [
			{
				path: AppPath.dashboard.bugHistory.path,
				element: <LazyBugHistoryListPage />,
				handle: {
					crumb: {
						text: 'Bug History',
						href: AppPath.dashboard.bugHistory.path,
					},
				},
			},
		]
	},
];
export const appRouter = createBrowserRouter(routesConfig);
