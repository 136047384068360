import Link, { LinkProps } from '@amzn/awsui-components-react/polaris/link';

interface InfoLinkProps {
	id?: string;
	ariaLabel?: string;
	onFollow: LinkProps['onFollow'];
}

export const InfoLink = (props: InfoLinkProps) => (
	<Link variant='info' {...props}>
		Info
	</Link>
);
