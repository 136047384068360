import React, {useEffect, useState} from 'react';
import {Navigate} from 'react-router-dom';
import {AppPath} from 'src/app-path';
import useAuthContext from 'src/auth/auth-context';
import CustomLoadingOverlay from 'src/components/loading/custom-loading-overlay';

const SecuredRoute = ({children}: { children: React.JSX.Element }): React.JSX.Element | null => {
    const auth = useAuthContext();
    const [loaded, setLoaded] = useState(false);
    const [authenticated, setAuthenticated] = useState(false);

    useEffect(() => {
        const checkAuth = async () => {
            setLoaded(false);
            try {
                const profile = await auth.login();
                if (profile.userId) {
                    setAuthenticated(true);
                }
            } catch {
                setAuthenticated(false);
            } finally {
                setLoaded(true);
            }
        };
        checkAuth();
    }, []);


    if (!loaded) {
        return <CustomLoadingOverlay
            active={!loaded}
            text='Checking your credentials...'/>;
    }

    if (loaded && !authenticated) {
        return <Navigate to={AppPath.login.index} replace/>;
    }

    return children;
};

export default SecuredRoute;
