import BreadcrumbGroup, { BreadcrumbGroupProps } from '@amzn/awsui-components-react/polaris/breadcrumb-group';
import { SideNavigationProps } from '@amzn/awsui-components-react/polaris/side-navigation';

export function Breadcrumbs({
	items,
	onFollow,
}: {
	items: BreadcrumbGroupProps['items'];
	onFollow: SideNavigationProps['onFollow'];
}) {
	return (
		<BreadcrumbGroup
			onFollow={onFollow}
			items={[{ text: 'Home', href: '/' }, ...items]}
			expandAriaLabel='Show path'
			ariaLabel='CommonNavigation Path'
		/>
	);
}
