import axiosInstance from 'src/service/axios-service';
import {ApiPath} from 'src/app-path';
import {IGetProfileOperationResponse} from 'src/type/operation/user-operation';


export class AuthService {
    static async getUserProfile(): Promise<IGetProfileOperationResponse> {
        return axiosInstance.get(ApiPath.user.profile.getProfile);
    }
}
