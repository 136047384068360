import SideNavigation, { SideNavigationProps } from '@amzn/awsui-components-react/polaris/side-navigation';

const navHeader = { text: 'Tools & Services', href: '/' };
const defaultOnFollowHandler: SideNavigationProps['onFollow'] = (event) => {
	event.preventDefault();
};

interface NavigationProps {
	activeHref?: string;
	header?: SideNavigationProps['header'];
	items?: SideNavigationProps['items'];
	onFollowHandler?: SideNavigationProps['onFollow'];
}

export function CommonNavigation({
	activeHref,
	header = navHeader,
	items = [],
	onFollowHandler = defaultOnFollowHandler,
}: NavigationProps) {
	return <SideNavigation items={items} header={header} activeHref={activeHref} onFollow={onFollowHandler} />;
}
