import React, { Component, ErrorInfo } from 'react';

interface Props {
	children: React.ReactNode;
}

interface State {
	hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = { hasError: false };
	}

	componentDidCatch(error: Error, errorInfo: ErrorInfo) {
		console.error('We caught an error: ', error, errorInfo);
		this.setState({ hasError: true });
	}

	render() {
		if (this.state.hasError) {
			return <h1 data-testid="errorboundary">Oops! Sorry, something went wrong.</h1>;
		}

		return this.props.children;
	}
}

export default ErrorBoundary;
