import React, { createContext, Dispatch, SetStateAction, useState } from 'react';
import { useItemsLayout } from 'src/hooks/widgets/use-items-layout';
import { StoredWidgetPlacement } from 'src/pages/interfaces';

export interface DashboardContextContextType {
	isDarkTheme: boolean;
	toggleTheme: () => void;
	splitPanel: Element | null;
	setSplitPanel: Dispatch<SetStateAction<Element | null>>;
	splitPanelOpen: boolean;
	setSplitPanelOpen: Dispatch<SetStateAction<boolean>>;
	itemsRef: React.Ref<any>;
	layout: readonly StoredWidgetPlacement[];
	setLayout: (layout: readonly StoredWidgetPlacement[]) => void;
	resetLayout: () => void;
}

export const DashboardContext = createContext<DashboardContextContextType | null>(null);

const DashboardProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
	const [isDarkTheme, setIsDarkTheme] = useState(false);
	const [splitPanelOpen, setSplitPanelOpen] = useState(false);
	const [splitPanel, setSplitPanel] = useState<Element | null>(null);
	const { ref: itemsRef, layout, setLayout, resetLayout } = useItemsLayout();

	const toggleThemeDarkLight = (): void => {
		setIsDarkTheme(!isDarkTheme);
	};

	return (
		<DashboardContext.Provider
			value={{
				isDarkTheme,
				toggleTheme: toggleThemeDarkLight,
				splitPanel,
				setSplitPanel,
				setSplitPanelOpen,
				splitPanelOpen,
				itemsRef,
				layout,
				setLayout,
				resetLayout,
			}}
		>
			{children}
		</DashboardContext.Provider>
	);
};

export default DashboardProvider;
