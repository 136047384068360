import {AuthPortalLoginUrlBuilder, AuthPortalLogoutUrlBuilder,} from '@amzn/authentication-portal-client-lib';
import {Devo} from '@amzn/authentication-portal-client-lib/dist/types';
import {ASSOCIATION_HANDLE, FRONTEND_URL, isLocal} from 'src/env/env';

export class AuthPortalMiddleware {
    public static async createLoginUrl() {
        return await new AuthPortalLoginUrlBuilder()
            .withDomain(isLocal() ? Devo.US : Devo.US)
            .withAssociationHandle(ASSOCIATION_HANDLE)
            .withReturnToUrl(FRONTEND_URL)
            .withMaxAuthAge(0)
            .withEnablePasswordAssert(true)
            .build();
    }

    public static async createLogoutUrl(){
        return await new AuthPortalLogoutUrlBuilder()
            .withDomain(isLocal() ? Devo.US : Devo.US)
            .withAssociationHandle(ASSOCIATION_HANDLE)
            .withReturnToUrl(FRONTEND_URL)
            .build();
    }
}