import { BoardProps } from '@amzn/awsui-board-components/board';
import { StoredWidgetPlacement } from '../../interfaces';
import { WidgetConfig, WidgetDataType } from 'src/pages/dashboard/widgets/interfaces';
import { DashboardStatsOverview } from 'src/pages/dashboard/widgets/stats-overview';

export const allWidgets: Record<string, WidgetConfig> = {
	DashboardStatsOverview,
};

const defaultLayout: ReadonlyArray<StoredWidgetPlacement> = [{ id: 'DashboardStatsOverview' }];

export function getDefaultLayout() {
	return defaultLayout;
}

export function exportLayout(
	items: ReadonlyArray<BoardProps.Item<WidgetDataType>>,
): ReadonlyArray<StoredWidgetPlacement> {
	return items.map((item) => ({
		id: item.id,
		columnSpan: item.columnSpan,
		columnOffset: item.columnOffset,
		rowSpan: item.rowSpan,
	}));
}

export function getBoardWidgets(layout: ReadonlyArray<StoredWidgetPlacement>) {
	return layout.map((position) => {
		const widget = allWidgets[position.id];
		return {
			...position,
			...widget,
			columnSpan: position.columnSpan ?? widget.definition?.defaultColumnSpan ?? 1,
			rowSpan: position.rowSpan ?? widget.definition?.defaultRowSpan ?? 2,
		};
	});
}

export function getPaletteWidgets(layout: ReadonlyArray<StoredWidgetPlacement>) {
	return Object.entries(allWidgets)
		.filter(([id]) => !layout.find((position) => position.id === id))
		.map(([id, widget]) => ({ id, ...widget }));
}
