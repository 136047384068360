import React, {createContext, ReactNode, useState} from 'react';
import Alert from '@amzn/awsui-components-react/polaris/alert';
import Modal from '@amzn/awsui-components-react/polaris/modal';
import Box from '@amzn/awsui-components-react/polaris/box';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import Button from '@amzn/awsui-components-react/polaris/button';

type Alert = {
    title: string;
    message: string;
    onDismiss: (() => void) | null | undefined
};

type AlertContext = {
    showAlert: (title: string, message: string, onDismiss?: (() => void) | null | undefined) => void;
};

type AlertContextProvider = {
    children: ReactNode;
};

export const AlertContext = createContext<AlertContext>({
    showAlert: () => {},
});

export const AlertProvider: React.FC<AlertContextProvider> = ({children}) => {
    const [alertMessage, setAlertMessage] = useState<Alert | null>(null);
    const [visible, setVisible] = React.useState(false);

    const contextValue: AlertContext = {
        showAlert: (title, message, onDismiss ) => {
            const alertMessage: Alert = {
                title,
                message,
                onDismiss
            };
            setAlertMessage(alertMessage);
            setVisible(true);
        },
    };

    return (
        <AlertContext.Provider value={contextValue}>
            {alertMessage &&
                <Modal
                    data-testid="alert-modal-id"
                    onDismiss={() => setVisible(false)}
                    visible={visible}
                    footer={
                        <Box float="right">
                            <SpaceBetween direction="horizontal" size="xs">
                                <Button data-testid="alert-dismiss-btn-id" variant="primary" onClick={() => {
                                    setVisible(false);
                                    alertMessage.onDismiss && alertMessage.onDismiss();
                                    setAlertMessage(null);
                                }}>Ok</Button>
                            </SpaceBetween>
                        </Box>
                    }
                    header={alertMessage.title}
                >
                    {alertMessage.message}
                </Modal>}
            {children}
        </AlertContext.Provider>
    );
};