import { useState } from 'react';
import { save, load } from 'src/helper/local-storage';

export function useLocalStorage<T>(key: string, defaultValue?: T) {
	const [value, setValue] = useState<T>(() => load(key) ?? defaultValue);

	function handleValueChange(newValue: T) {
		setValue(newValue);
		save(key, newValue);
	}

	return [value, handleValueChange] as const;
}
